import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import {
  Button,
  Form,
  Message,
  Spacing,
  TextField
} from 'groundkeeper-component-library';

export default function LoginForm(props) {
  const intl = useIntl();
  const router = useRouter();

  return (
    <Form
      onSubmit={props.handleSubmit}
      initialValues={{ email: '', password: '' }}
      render={({ form, handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit} data-testid="loginForm">
          <TextField
            id="email"
            disabled={props.disabled}
            required
            requiredWithoutAsterisk
            isEmail
            name="email"
            fullWidth
            label={intl.formatMessage({
              id: 'email',
              defaultMessage: 'Email'
            })}
          />
          <Spacing y={1} />
          <TextField
            id="password"
            disabled={props.disabled}
            required
            requiredWithoutAsterisk
            fullWidth
            name="password"
            type="password"
            label={intl.formatMessage({
              id: 'password',
              defaultMessage: 'Password'
            })}
          />
          {submitError && (
            <>
              <Spacing y={1} />
              <Message type="error">{submitError}</Message>
            </>
          )}
          <Spacing y={2} />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            loading={props.loading}
            disabled={props.disabled || submitting}
          >
            {intl.formatMessage({ id: 'login', defaultMessage: 'Login' })}
          </Button>
          {props.registrationEnabled ? (
            <>
              <Spacing y={2} />
              <Button
                onClick={() => router.push('/register')}
                color="primary"
                variant="outlined"
                fullWidth
                disabled={props.disabled || submitting}
              >
                {intl.formatMessage({
                  id: 'register',
                  defaultMessage: 'Register'
                })}
              </Button>
            </>
          ) : null}
        </form>
      )}
    />
  );
}

LoginForm.propTypes = {
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  registrationEnabled: PropTypes.bool
};

LoginForm.defaultProps = {
  disabled: false,
  loading: false,
  registrationEnabled: false
};
