import Head from 'next/head';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import {
  Box,
  Content,
  FORM_ERROR,
  Spacing
} from 'groundkeeper-component-library';

import Globals from 'shared/App/utils/Globals';
import Link from 'shared/App/components/Link';
import LoginForm from 'shared/App/forms/LoginForm';
import formatImageUrl from 'shared/App/utils/formatImageUrl';
import logoutSideEffects from 'shared/Redux/utils/logout';

import parseApiError from 'shared/SharedJS/src/utils/apiError/parseApiError';
import selectDefaultOrganizationId from 'shared/Redux/selectors/organization/selectDefaultOrganizationId';
import selectOrganizationById from 'shared/Redux/selectors/organization/selectOrganizationById';
import { login } from 'shared/Redux/slices/auth.slice';

export default function Login() {
  const intl = useIntl();
  const router = useRouter();
  const dispatch = useDispatch();
  const organizationId = useSelector(selectDefaultOrganizationId);
  const organization = useSelector(selectOrganizationById(organizationId));
  const [loading, setLoading] = useState(false);

  const settings = Globals.settings;
  const { redirectUrl } = router.query;

  async function handleSubmit(values) {
    setLoading(true);

    logoutSideEffects();

    const result = await dispatch(
      login({ email: values.email, password: values.password })
    );

    if (result.error) {
      const errorMessage = parseApiError(result, intl);
      setLoading(false);

      return {
        [FORM_ERROR]: errorMessage
      };
    }

    if (redirectUrl) {
      const queryParams = Object.keys(router.query).filter(
        key => key !== 'redirectUrl'
      );

      let redirectUrlWithParams = redirectUrl;

      if (queryParams.length > 0) {
        const queryParamsString = queryParams
          .map(a => `&${a}=${router.query[a]}`)
          .reduce((a, b) => a + b);

        redirectUrlWithParams = `${redirectUrlWithParams}&${queryParamsString}`;
      }

      router.push(`${redirectUrlWithParams}`);
    } else {
      router.push('/dashboard');
    }

    return {};
  }

  return (
    <>
      <Head>
        <title>
          {Globals.settings.appEnv ? `${Globals.settings.appEnv} ` : ''}
          {`${intl.formatMessage({
            id: 'login',
            defaultMessage: 'Login'
          })} ${intl.formatMessage({
            id: 'application_base_title',
            defaultMessage: '- Management portal'
          })}`}
        </title>
      </Head>
      <Content
        size="small"
        // title={intl.formatMessage({ id: 'login', defaultMessage: 'Login' })}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={1.25}
          mb={2.5}
        >
          <img
            alt="Logo der Anwendung"
            src={formatImageUrl(organization?.logo || settings.logoBig)}
            style={{ maxWidth: '100%' }}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box width={1}>
            <LoginForm
              handleSubmit={values => handleSubmit(values)}
              disabled={loading}
              loading={loading}
              registrationEnabled={false}
            />
            <Spacing y={3} />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ fontSize: '.8rem' }}
            >
              <Link href="/password_reset">
                {intl.formatMessage({
                  id: 'password_forgotten',
                  defaultMessage: 'Forgot password?'
                })}
              </Link>
            </Box>
          </Box>
        </Box>
      </Content>
    </>
  );
}
